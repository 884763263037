import React from 'react'
import { BiMapPin } from "react-icons/bi"
import { FiPercent } from "react-icons/fi"
import { ImEye } from "react-icons/im" //AGREGAR GrDocumentUpdate CUANDO TENGA LO DE LA FICHA TECNICA
import { FaTemperatureLow } from "react-icons/fa"
import { MdOutlineSimCardDownload } from "react-icons/md"
import { GiGrapes, GiLips, GiNoseFront, GiKnifeFork, GiBarrel } from "react-icons/gi"

export const Caracteristicas = ({faseVis, faseOlf, faseGus, cosecha, descUno, descDos, grado, maridaje, temp, region, nombre, tipo, ubicacion}) => {

        const verificarFicha = () => {
          if (nombre === "Trashumante Tinto") {
            return null
          } else if (nombre === "Trashumante Rosado") {
            return null
          } else {
            return (
              <>
                <div className="content-fichaBoxCol">
                    <a href={`/pdf/${nombre}.pdf`} download className='content-fichaIcon'>
                      <MdOutlineSimCardDownload className="content-icon"/>
                    </a>
                    <p className="content-fichaBoxTitle">DESCARGAR FICHA TECNICA</p>
                </div>
              </>
            )
          }
        }


        return (
          <>
            <section className='content-description'>
                <div className='content-character'>
                  <div className='content-characterTitle'>
                    <p>CARACTERISTICAS</p>
                  </div>
                  <p className='content-characterDesc'>{descUno}</p>
                  <div className={nombre === "Reserva" ? "content-datos" : "content-datosCenter"}>
                    {nombre === "Reserva"
                    ? <div className='content-premioBox'>
                        <p className='content-premioTitle'>AÑADA 2018</p>
                        <div className='content-premioRow'>
                          <hr/>
                          <div className='content-premioDesc'>
                            <p>Expovina Wine</p>                          
                            <p>Trophy 2022</p>                          
                            <p>ORO</p>                          
                          </div>
                          <hr/>
                          <div className='content-premioDesc'>
                            <p>Guía Peñín 2023</p>
                            <p>93 puntos</p>
                          </div>
                        </div>
                      </div>
                    : null
                    }
                    <div className='content-map'>
                      <div className='content-characterTitle'>
                        <p>D.O - {region}</p>
                      </div>
                      <iframe className='map' title={nombre} src={ubicacion}allowFullScreen="" loading="lazy"referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                  
                  <div className='content-characterBox'>
                    {descDos 
                      ? <>
                          <div className='content-characterTitle'>
                            <p>CARACTERISTICAS CLIMATOLOGICAS</p>
                            <p>COSECHA {cosecha}</p>
                          </div>
                          <p className='content-characterDesc'>{descDos}</p>
                        </>
                      : null
                    }
                  </div>
                </div>
                <div className='content-ficha'>
                  <div className="content-fichaDesc">
                    <div className='content-fichaTitle'>
                      <p>FICHA TECNICA</p>
                    </div>
                    <div className='content-fichaBox'>
                      <div className="content-fichaBoxCol">
                        <div className='content-fichaIcon'>
                          <BiMapPin className="content-icon"/>
                        </div>
                        <p className="content-fichaBoxTitle">DENOMINACIÓN DE ORIGEN</p>
                        <p className="content-fichaBoxDesc">{region}</p>
                      </div>
                      <div className="content-fichaBoxCol">
                        <div className='content-fichaIcon'>
                          <GiGrapes className="content-icon"/>
                        </div>
                        <p className="content-fichaBoxTitle">VARIEDAD DE LA UVA</p>
                        <p className="content-fichaBoxDesc">{tipo}</p>
                      </div>
                      {grado 
                      ?
                        <div className="content-fichaBoxCol">
                          <div className='content-fichaIcon'>
                            <FiPercent className="content-icon"/>
                          </div>
                          <p className="content-fichaBoxTitle">CONTENIDO ALCOHÓLICO</p>
                          <p className="content-fichaBoxDesc">{grado}</p> 
                        </div>
                      : null
                      }
                      {maridaje 
                      ?
                        <div className="content-fichaBoxCol">
                          <div className='content-fichaIcon'>
                            <GiKnifeFork className="content-icon"/>
                          </div>
                          <p className="content-fichaBoxTitle">MARIDAJE</p>
                          <p className="content-fichaBoxDesc">{maridaje}</p> 
                        </div>
                      : null
                      }
                      <div className="content-fichaBoxCol">
                        <div className='content-fichaIcon'>
                          <FaTemperatureLow className="content-icon"/>
                        </div>
                        <p className="content-fichaBoxTitle">TEMPERATURA DE SERVICIO</p>
                        <p className="content-fichaBoxDesc">{temp}</p> 
                      </div>
                      {nombre === "Reserva" 
                      ?
                        <div className="content-fichaBoxCol">
                          <div className='content-fichaIcon'>
                            <GiBarrel className="content-icon"/>
                          </div>
                          <p className="content-fichaBoxDesc">26 meses en roble americano</p>
                        </div>
                      : null
                      }
                      {nombre === "Viña Collada" 
                      ?
                        <div className="content-fichaBoxCol">
                          <div className='content-fichaIcon'>
                            <GiBarrel className="content-icon"/>
                          </div>
                          <p className="content-fichaBoxDesc">4 meses en roble americano</p>
                        </div>
                      : null
                      }
                      {verificarFicha()}
                    </div>
                  </div>
                  <div className="content-fichaDesc">
                      <div className='content-fichaTitle'>
                        <p>NOTA DE CATA</p>
                      </div>
                      <div className="content-cataBoxCol">
                        <div className='content-fichaIcon'>
                          <ImEye className="content-icon"/>
                        </div>
                        <p className="content-fichaBoxTitle">FASE VISUAL</p>
                        <p className="content-fichaBoxDesc">{faseVis}</p>
                      </div>
                      <div className="content-cataBoxCol">
                        <div className='content-fichaIcon'>
                          <GiNoseFront className="content-icon"/>
                        </div>
                        <p className="content-fichaBoxTitle">FASE OLFATIVA</p>
                        <p className="content-fichaBoxDesc">{faseOlf}</p>
                      </div>
                      <div className="content-cataBoxCol">
                        <div className='content-fichaIcon'>
                          <GiLips className="content-icon"/>
                        </div>
                        <p className="content-fichaBoxTitle">FASE GUSTATIVA</p>
                        <p className="content-fichaBoxDesc">{faseGus}</p>
                      </div>
                  </div>
                </div>
            </section>
          </>
        )

}
