import { Item } from "../Item/Item"


export const ItemList = ( {productos, imagenes} ) => {

    const productosConImagenes = productos.map((producto) => {
        const imagen = imagenes.find((imagen) => imagen.nam === producto.dato + '.png');
        return { ...producto, ...imagen };
    }).sort((a, b) => b.precio - a.precio);

    return(
        <div className="card-content">
            {productosConImagenes.map((el) => <Item key={el.id} {...el}/>)}
        </div>
    )
}